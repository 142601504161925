
















































































































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import RangerTimePicker from '@/components/ui/RangerTimePicker.vue'
import type { FiltroDeVenda } from '@/models'
import { limparFiltroDosCamposDeVenda } from '@/shareds/venda-shareds'
import { nextTick } from '@/shareds/utils'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue'
import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'

@Component({
	components: {
		RangeDatePicker,
		RangerTimePicker,
		SeletorDeCliente,		
		SeletorDeUsuario,
	},
})
export default class CamposDeFiltrosDeVendas extends Vue {
	@Prop({ type: Boolean, default: true }) exibirSeletorDeUsuario!: boolean
	@Prop({ type: Boolean, default: false }) carregando!: boolean
	@PropSync('value', {
		default: () => ({
			busca: '',
			loja: '',
			datas: [],
			horas: [null, null],
			cliente: '',
			exibeVendasComErros: false,
			ambientes: '',
			vendasComOrcamento: false,
			vendasComPagamentoCancelado: false,
			origem: [],
			vendasConsignadaDemonstracao: false,
			vendedorId: '',
		}),
	}) filtro!: FiltroDeVenda
	mostra: boolean | null = null
	ambientes: string[] = []

	mostrar() {
		this.mostra = true
	}

	ocultar() {
		this.mostra = false
	}

	limparFiltros() {
		this.$emit('limpar')
		this.$emit('input', limparFiltroDosCamposDeVenda())
	}

	@Watch('paginacao', { deep: true, immediate: true })
	onChangePaginacao() {
		this.$emit('buscar', this.filtro)
	}

	async aplicarFiltro() {
		await nextTick()
		this.$emit('buscar', this.filtro)
	}
}
