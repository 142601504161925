var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.aplicarFiltros.apply(null, arguments)}}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('SeletorDeLojasDoUsuario',{staticStyle:{"margin-left":"20px"},attrs:{"prepend-icon":"mdi-store","label":"Loja","clearable":"","idDaLojaSalva":_vm.filtro.lojaId,"disabled":_vm.buscando},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.buscando,"disabled":_vm.buscando}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Buscar ")],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"filled":"","disabled":_vm.buscando},on:{"click":_vm.abrirFiltros}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Filtros ")],1)],1)],1)],1)],1)],1),_c('CamposDeFiltrosDeVendas',{ref:"dialogoDeFiltros",attrs:{"carregando":_vm.buscando,"exibirSeletorDeUsuario":false},on:{"buscar":_vm.aplicarFiltros,"limpar":_vm.limparSelecaoDeFiltros},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}}),_c('v-card',{staticClass:"mt-2"},[_c('v-container',{attrs:{"fluid":""}},[_c('GridDeNotas',{attrs:{"vendas":_vm.vendas,"loading":_vm.buscando,"footer-props":{ itemsPerPageOptions: [ 5, 10, 15, 30, 50 ] },"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDaVenda',{attrs:{"venda":item,"esconderBotaoImprimirDanfe":_vm.bloquearEmissaoDeNovaNota(item)},on:{"input":_vm.atualizarVenda,"buscar":_vm.buscar},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_vm._v(" Detalhes da venda ")])]}}],null,true)}),_c('ConfirmacaoComMotivoFiscal',{attrs:{"width":"300","titulo":"Cancelar nota(s)?","subtitulo":("<div>" + (_vm.displayNotasFiscais(item)) + "</div>")},on:{"confirmar":function (motivo) { return _vm.cancelarNotasFiscais(item, motivo); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","disabled":_vm.cancelandoNotasFiscais.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.cancelandoNotasFiscais.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-text-box-remove')+" ")])]}}],null,true)},[_vm._v(" Cancelar Nota(s) ")])]}}],null,true)}),(_vm.temNotaParaEnviarEmail(item) && _vm.podeEnviarPorEmail)?_c('Confirmacao',{attrs:{"width":"400","titulo":"Deseja enviar a(s) notas por e-mail?","subtitulo":"<div> Apenas as notas já emitidas serão enviadas. </div>"},on:{"confirmar":function () { return _vm.enviarPorEmail(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","disabled":_vm.enviandoEmail.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.enviandoEmail.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-email-fast')+" ")])]}}],null,true)},[_vm._v(" Enviar Por Email ")])]}}],null,true)}):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c('RodapePersonalizado',{attrs:{"paginacao":_vm.paginacao,"total":_vm.itensFormatados.length,"loading":_vm.buscando},on:{"update:paginacao":function($event){_vm.paginacao=$event},"navegar":_vm.navegar}})]},proxy:true}],null,true)})],1)],1),_c('DialogoDeInformarEmail',{ref:"dialogoDeInformarEmail"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }